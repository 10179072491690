<template>
    <div>
        <!-- 搜索 -->
        <!-- <div class="search">
            <el-input
                class="filter_search_input"
                placeholder="搜索候选人/人才库"
                v-model="search.keyword"
                clearable
            >
            </el-input>
            <div class="custom_button no_select">导入简历</div>
            <div
                class="custom_button no_select blue"
                @click="$router.push({ name: 'RecruitmentPost' })"
            >
                添加招聘职位
            </div>
        </div> -->
        <div style="margin-top: 100px"></div>
        <!-- 招聘概览 -->
        <div class="recruitment">
            <div class="recruitment_top">
                <div>待招聘 {{ statistics.recruited_num }}人</div>
                <el-divider direction="vertical"></el-divider>
                <div>候选人 {{ statistics.candidate_num }}人</div>
            </div>
            <div class="recruitment_bottom no_select">
                <div class="recruitment_bottom_item">
                    <div class="recruitment_bottom_item_title">初筛</div>
                    <div class="recruitment_bottom_item_ul">
                        <div>
                            <div>待初筛</div>
                            <div>{{ statistics.status1 }}</div>
                        </div>
                        <div>
                            <div>初筛通过</div>
                            <div>{{ statistics.status2 }}</div>
                        </div>
                    </div>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div class="recruitment_bottom_item">
                    <div class="recruitment_bottom_item_title">面试</div>
                    <div class="recruitment_bottom_item_ul">
                        <div>
                            <div>待安排面试</div>
                            <div>{{ statistics.status3 }}</div>
                        </div>
                        <div>
                            <div>已面试</div>
                            <div>{{ statistics.status4 }}</div>
                        </div>
                        <div>
                            <div>面试通过</div>
                            <div>{{ statistics.status5 }}</div>
                        </div>
                    </div>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div class="recruitment_bottom_item">
                    <div class="recruitment_bottom_item_title">录用</div>
                    <div class="recruitment_bottom_item_ul">
                        <div>
                            <div>已发Offer</div>
                            <div>{{ statistics.status6 }}</div>
                        </div>
                        <div>
                            <div>待入职</div>
                            <div>{{ statistics.status7 }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 职位概览 -->
        <div class="post">
            <div class="post_title">
                最近跟进职位
                <el-tooltip
                    effect="light"
                    content="Top Center 提示文字"
                    placement="top"
                >
                    <i class="el-icon-warning-outline"></i>
                </el-tooltip>
            </div>
            <div class="post_cards">
                <div
                    class="post_cards_item"
                    v-for="(item,index) in statistics.job_list"
                    :key="index"
                >
                    <div class="post_cards_item_title">
                        <div>{{ item.name }}</div>
                        <div>{{ item.status_value }}</div>
                    </div>
                    <el-divider></el-divider>
                    <div class="post_cards_item_content">
                        <div>
                            <div>候选人</div>
                            <div>{{ item.status1 }}</div>
                        </div>
                        <div>
                            <div>待面试</div>
                            <div>{{ item.status2 }}</div>
                        </div>
                        <div>
                            <div>待入职</div>
                            <div>{{ item.status7 }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            search: {
                keyword: "",
            },
            statistics: {},
        };
    },
    created() {
        this.getRecruitmentStatistics()
            .then((res) => {
                console.log(res);
                this.statistics = res.data.data;
            })
            .catch((e) => {
                console.error(e);
            });
    },
    methods: {
        ...mapActions("recruitment", ["getRecruitmentStatistics"]),
    },
};
</script>

<style lang="scss" scoped>
.search {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 60px 0;

    .filter_search_input {
        width: 640px;
        height: 48px;

        ::v-deep .el-input__inner {
            height: 48px;
            caret-color: #4db9d5;

            &:focus {
                border-color: #4db9d5;
            }
        }
    }

    .custom_button {
        margin-left: 15px;
        height: 48px;
        line-height: 48px;
        width: 160px;
        font-size: 16px;

        &.blue {
            background-color: #406bff;
        }
    }
}

.recruitment {
    width: 1200-80px;
    height: 294px;
    margin: 0 auto;
    background-color: #ffffff;
    border: 1px solid #f0f4f8;
    box-shadow: 0px 6px 25px 0px rgba(223, 227, 236, 0.3);
    border-radius: 5px;
    padding: 0 40px;

    .recruitment_top {
        display: flex;
        align-items: center;
        padding: 24px 0;
        border-bottom: 1px solid #f0f4f8;
        font-size: 14px;
        font-weight: 400;
        color: #1c2438;
    }

    .recruitment_bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        .el-divider {
            height: auto;
        }

        .recruitment_bottom_item {
            width: 320px;

            .recruitment_bottom_item_title {
                font-size: 20px;
                font-weight: bold;
                color: #1c2438;
            }

            .recruitment_bottom_item_ul {
                > div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    color: #495060;
                    padding: 9px 15px 9px 25px;
                    margin: 10px 0;
                    position: relative;

                    &::before {
                        content: "";
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: #e1e6eb;
                        position: absolute;
                        left: 15px;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }

                    &:hover {
                        background-color: #f5f7fa;
                        border-radius: 16px;
                    }

                    > div:last-child {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.post {
    width: 1200px;
    margin: 15px auto;

    .post_title {
        font-size: 14px;
        font-weight: 400;
        color: #1c2438;
        padding: 15px 0;
    }

    .post_cards {
        width: 1150px;
        display: grid;
        grid-template-columns: repeat(3, 335px);
        justify-content: space-between;

        .post_cards_item {
            width: 100%;
            padding: 0 25px;
            background-color: #ffffff;
            border: 1px solid #f0f4f8;
            box-shadow: 0px 6px 25px 0px rgba(223, 227, 236, 0.3);
            border-radius: 5px;
            margin-bottom: 20px;

            .post_cards_item_title {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                padding-top: 20px;

                > div:nth-child(1) {
                    font-size: 16px;
                    font-weight: 400;
                    color: #1c2438;
                }

                > div:nth-child(2) {
                    font-size: 12px;
                    font-weight: 400;
                    color: #80848f;
                }
            }

            .post_cards_item_content {
                display: flex;

                > div {
                    padding-bottom: 20px;
                    flex: 1;

                    > div:nth-child(1) {
                        font-size: 12px;
                        font-weight: 400;
                        color: #80848f;
                    }

                    > div:nth-child(2) {
                        font-size: 16px;
                        font-weight: 400;
                        color: #1c2438;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
</style>
